import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { ToolTip } from "./ToolTip";

export const FieldContainer = ({
	id,
	name,
	label,
	help_text,
	required,
	children,
	disabled,
}) => {
	return (
		<div className="group relative flex flex-col gap-2">
			{children}
			<label
				className={"absolute px-1 translate-y-[-12px] hover:z-10 peer-focus:text-blue left-8 bg-white capitalize flex gap-1" + (disabled ? " text-grey" : " text-black")}
				htmlFor={id}
			>
				{label}
				{help_text && <ToolTip>{help_text}</ToolTip>}
			</label>
			<ErrorMessage
				name={name}
				render={({ message }) => <span className="text-red whitespace-nowrap">{message}</span>}
			/>
		</div>
	);
};
